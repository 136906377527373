export const getEventName = (key) => {
  const events = {
    "garbhsanskar-class": "GS_workshop_payment",
    "garbhsanskar-class-v1": "GS_workshop_payment",

    "garbh-sanskar-v2": "GS_workshop_payment",

    "beej-sanskar-v2": "bs_payment_done",
    "beejsanskar-class": "bs_payment_done",
    "beejsanskarv1-class": "bs_payment_done",

    "superparenting-class": "Parenting_workshop_registrations",
    "fertilityBooking": "fertility_booked",
    "slot-booking": "wcp_slot_booked",
    "dummyPage": "ts_gtm",
    // "slot-booking" : "wcp_slot_booked",
    //  "WCP" : "wcp_purchase",
    //  "StandardProgram" : "stnadard_payment_done",
    //  "SuperParenting" : "SuperParenting_purchase"
  };
  return events[key];
};

export const getExternalID = (key) => {
  const events = {
    "garbhsanskar-class": "gs_123",
    "beejsanskar-class": "bs_123",
    "superparenting-class": "sp_123",
    "slot-booking": "wcp_slot_booked",
    WCP: "wcp_123",
    StandardProgram: "std_123",
    SuperParenting: "Spp_123",
  };
  return events[key];
};

export function removeKeysFromObject(obj, keysToRemove) {
  // Create a new object to avoid mutating the original one
  const newObj = { ...obj };

  // Iterate over the keys to remove and delete them from the new object
  keysToRemove.forEach((key) => {
    delete newObj[key];
  });

  return newObj;
}

export function getFbpCookie() {
  let fbpValue = null; // Initialize variable to hold the _fbp value
  const cookies = document.cookie.split(";"); // Get all cookies and split them into an array

  // Loop through each cookie to find the one that starts with '_fbp'
  cookies.forEach((cookie) => {
    if (cookie.trim().startsWith("_fbp=")) {
      fbpValue = cookie.split("=")[1]; // Extract the value after the '=' symbol
    }
  });

  return fbpValue; // Return the _fbp value
}
