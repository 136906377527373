import axios from "axios";
import { filterObject, formatEventTime } from "../utils/ui-utils";
import { json } from "react-router";
import store from "../store/store";
import { eventActions } from "../store/slices/event-slice";

// * required body params
// const userId = request.body["userId"];
// const selectedPlanId = request.body["selectedPlanId"];
// optional appliedCoupon
export async function createOrder(userData) {
  let _data = filterObject(userData);

  const url =
    "https://us-central1-pruoo-app.cloudfunctions.net/createOrderForWebsitePayment";
  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ ..._data }),
    url,
  };
  const response = await axios(options);
  const data = response.data;

  if (data.result !== "success") {
    throw new Error(data.message || "Could not create quote.");
  }
  const { order, amount } = data;

  return { order, amount };
}

export async function appendDataIntoSheet(userData) {
  console.log(JSON.stringify({ ...userData }));
  const url =
    "https://us-central1-pruoo-app.cloudfunctions.net/appendDataIntoSheet";
  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ ...userData }),
    url,
  };
  await axios(options);
}

export async function validateCoupons(
  couponCode,
  selectedPlanId,
  lmp,
  programStartDate
) {
  try {
    const url =
      "https://us-central1-pruoo-app.cloudfunctions.net/validateCouponCodes";
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify({
        couponCode,
        selectedPlanId,
        lmp,
        programStartDate,
      }),
      url,
    };
    const response = await axios(options);

    if (response.status === 200) {
      const data = response.data;
      return {
        ...data,
        isValid: true,
        discount: data.discount || 0,
        message: "Coupon Applied!!",
      };
    }
    return {
      isValid: false,
      discount: 0,
      message: response?.data?.message || "Invalid Coupon!!",
      discountedPlanId: null,
      discountOnPerMonthPlan: 0,
    };
  } catch (err) {
    console.log(err);
    return {
      isValid: false,
      discount: 0,
      message: "Invalid Coupon!!",
      discountedPlanId: null,
      discountOnPerMonthPlan: 0,
    };
  }
}

export async function getAvailableSlots() {
  try {
    const url =
      "https://us-central1-pruoo-app.cloudfunctions.net/getAvailableSlots";
    const options = {
      method: "POST",
      withCredentials: false,
      headers: { "content-type": "application/json" },
      url,
    };
    const response = await axios(options);
    const data = response.data;

    if (data.result !== "success") {
      throw new Error(data.message || "Could not fetch slots.");
    }
    return data.data;
  } catch (err) {
    console.log(err);
  }
}

export const bookSlot = async (data) => {
  try {
    const url =
      "https://us-central1-pruoo-app.cloudfunctions.net/websiteRequestToBookSlot";
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify(data),
      url,
    };
    const response = await axios(options);
    const _data = response.data;

    if (_data.result !== "success") {
      throw new Error(_data.message || "Could not book slot.");
    }
    console.log(JSON.stringify(_data));
    return _data;
  } catch (err) {
    console.log(err);
    return {
      result: "failed",
      message: "Booking Failed, Please Try Again Later!",
    };
  }
};

export const getIpData = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    if (!response.ok) {
      throw new Error("Network response was not OK");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error in fetching ip data:", error);
  }
};

export const bookEventV2 = async (
  userId,
  email,
  phone_number,
  led_by,
  event,
  event_start_timestamp,
  event_end_timestamp,
  task_id
) => {
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/runHasuraFunctions`,
    {
      method: "POST",
      body: JSON.stringify({
        function: "bookDynamicEvent",
        payload: {
          userId,
          email,
          phone_number,
          led_by,
          event,
          event_start_timestamp,
          event_end_timestamp,
          task_id,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    const res = {
      isSuccess: false,
      heading: "Booking Failed!",
      description: data?.message || "Something went wrong! please try Again.",
    };
    return res;
  }
  const _data = data?.data;
  const res = {
    isSuccess: true,
    heading: "You are scheduled",
    description: data?.message,
    meeting: {
      person: _data?.event_detail?.led_by_expert?.name,
      title: _data?.event_detail?.event_name?.replaceAll("_", " "),
      date: _data && formatEventTime(_data?.event_detail),
    },
  };
  console.log(res);
  return res;
};

export const getEventsV2 = async (led_by, event_name) => {
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/runHasuraFunctions`,
    {
      method: "POST",
      body: JSON.stringify({
        function: "getDynamicCalendarEventByNameV2",
        payload: { led_by, event_name },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw json(
      { message: data?.message || "Couldn't get list of Events!" },
      { status: response.status }
    );
  }
  const _data = data.data;
  const res = {
    availableDates: Object.keys(_data),
    timeSlots: _data,
    ownerName: Object.values(_data)?.[0]?.[0]?.led_by,
    duration: Object.values(_data)?.[0]?.[0]?.event_duration,
  };
  console.log(res);
  return res;
};

export const getRenewalPln = async (userId, phase, yoga_specific) => {
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/runHasuraFunctions`,
    {
      method: "POST",
      body: JSON.stringify({
        function: "renewalPriceCal",
        payload: { userId: userId, phase: phase , yoga_specific : yoga_specific},
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw json(
      { message: data?.message || "Couldn't fetch renewal plan!" },
      { status: response.status }
    );
  }
  const _data = data.data;
  return _data;
};

export const validateWorkShopCoupon = async (couponValue) => {
  try {
    const response = await fetch(
      `https://app-356euzerpa-uc.a.run.app/website/validate-coupon?coupon=/${couponValue}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return { status: response.status, data };
  } catch (error) {
    throw error;
  }
};

export const reqSlotBookingRetool = async () => {
  console.log("called!!");
  let response = await fetch(
    `https://api.retool.com/v1/workflows/83aff711-38a9-4fb0-b6ad-1e38f4564379/startTrigger?workflowApiKey=retool_wk_68ca79b0aab347d4ae5f6f4b273b5113`,
    {
      method: "POST",
      body: JSON.stringify({
        userData: store.getState().user,
        ipData: store.getState().ip,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  console.log(data);
  if (!response.ok) {
    throw json(
      { message: data?.message || "Couldn't get list of Events!" },
      { status: response.status }
    );
  }
  const _data = data;
  return _data;
};

export const reqAppointmentBookingRetool = async (payload) => {
  let response = await fetch(
    `https://api.retool.com/v1/workflows/d4c3f297-ab8a-43fe-b786-5bf66df2bc1d/startTrigger?workflowApiKey=retool_wk_98f306be90104a86b1c4a263c0c5a510`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  console.log(data);
  if (!response.ok) {
    throw json(
      { message: data?.message || "Couldn't get list of Events!" },
      { status: response.status }
    );
  }
  const _data = data;
  return _data;
};

export const getWorkshopV2details = async (queryParams) => {
  // Convert the queryParams object to a query string
  const queryString = new URLSearchParams(queryParams).toString();

  const url = `https://journal-data-automation-356euzerpa-uc.a.run.app/website/workshop-v2?${queryString}`;

  let response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Couldn't get event's Info!");
  }
  const responseData = await response.json();
  store.dispatch(eventActions.setDate(responseData.data.date));
  store.dispatch(eventActions.setTime(responseData.data.time));
  store.dispatch(eventActions.setTitle(responseData.data.title));
  store.dispatch(eventActions.setWa(responseData.data.wa));
  return responseData.data;
};
