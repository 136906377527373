export const ROUTE_EVENT_BOOKING_V2 = "eventBookingV2";
export const ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2 = "eventBookingFeedbackV2";
export const ROUTE_REGISTRATION_SUCCESSFUL_PAGE = 'registrationSuccessPage';
export const ROUTE_TS_GTM_REGISTRATION = 'ts_gtm_registration';
export const ROUTE_THANK_YOU_FORM = 'thankyou';

export const ROUTE_FERTILITY_CLINIC = "fertilityClinic"
export const CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENTS = `appointment`; //
export const CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUMMARY_PAGE = `summary`; //
export const CHILD_ROUTE_FERTILITY_CLINIC_APPOINTMENT_SUCCESS = `success`; //

export const ROUTE_PREGNANCY_SLOT_BOOKING = "pregnancy-slot-booking"; //
export const ROUTE_BOOKING_CONFIRMATION = "booking-confirmation"; //
export const ROUTE_WORKSHOP_V2 = "workshop-v2"; //


export const ANALYTICS_KEYS_TO_REMOVE = ['workshopName','monthOfPregnancy','occupationDetail','selectedPlanId', ]


export const QUERY_PARAM_PAGE_TYPE = 'pagetype'
export const QUERY_PARAM_WHATSAPP_NUMBER = 'whatsAppNumber'
export const QUERY_PARAM_NAME = 'name'
export const QUERY_PARAM_WHATSAPP_GROUP = 'wag'
export const QUERY_PARAM_DIRECT = 'direct'
export const QUERY_PARAM_WORKSHOP_NAME = 'workshopName'
export const QUERY_PARAM_YOGA_SPECIFIC = 'yoga_specific'
export const QUERY_PARAM_SHOW_COUPON_CODE = 'showCC'
export const QUERY_PARAM_COUPON_CODE = 'couponCode'
